import ChevronRight from "@mui/icons-material/ChevronRight";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import banner from "assets/img/blog-banner.webp";
import blogBanner from "assets/img/blog_1.jpg";
import Footer from "components/Footer";
import { ImageHandle } from "components/ImageHandler";
import { TextEditor } from "components/TextEditor";
import format from "date-fns/format";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  blogsAction,
  languageSelector,
  selectBlogContent,
} from "../saga/blogSlice";
import "./blogStyles.css";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { formatDateStringToLocalDate } from "utils/common";
import { IMAGE_URL_ROOT } from "utils/constant";
import { LiaCalendarAltSolid } from "react-icons/lia";
import { BlogListItem } from "../components/BlogListItem";
import { useTranslation } from "react-i18next";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/vi";
import {
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import { IoIosLink } from "react-icons/io";
import { adminApi } from "pages/Admin/adminsApi";
dayjs.extend(utc);
dayjs.extend(timezone);
export default function DetailPage() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [formatedDate, setFormatedDate] = useState();
  const [contentTableHeads, setContentTableHeads] = useState();

  const blogContent = useSelector(selectBlogContent);
  const language = useSelector(languageSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [blogList, setBlogList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const getHeading = (content) => {
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(content, "text/html");
    const headings = Array.from(htmlDocument.querySelectorAll("h1, h2"));

    return headings.filter((e) => e.id);
  };

  useEffect(() => {
    dispatch(blogsAction.getBlogDetail(id));
    fetchGetAllBlogs();
  }, [id, dispatch]);

  useEffect(() => {
    const dataVal = blogContent && Date.parse(blogContent.created_at);
    dataVal && setFormatedDate(format(dataVal, "MMMM dd, yyyy"));

    blogContent && setContentTableHeads(getHeading(blogContent.content));
  }, [blogContent]);
  const fetchGetAllBlogs = () => {
    const getRandomElements = (arr, num) => {
      const shuffled = arr.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, num);
    };
    adminApi.getAllBlogs(1, 100).then((res) => {
      const allBlogs = res.data.data;

      // Check if there are at least 5 blogs to randomize
      const randomBlogs =
        allBlogs.length > 5 ? getRandomElements(allBlogs, 5) : allBlogs;

      setBlogList(randomBlogs);
      setTotalPage(res.data.total_pages);
    });
  };
  const targetRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: "smooth", // Cuộn mượt mà
        });
      }
    }, 500); // Thời gian chờ 500ms

    // Dọn dẹp timer khi component unmount
    return () => clearTimeout(timer);
  }, [id]);
  const blogCardList = (data) => {
    if (data == null || data.length === 0) {
      return null;
    }

    const blogList = data.map((item, index) => {
      return (
        <Grid key={index} item xs={12} sx={{ paddingLeft: "0px!important" }}>
          <Link to={`/blogs/${language === "vi" ? item.slugs : item.en_slugs}`}>
            <Box display={"flex"} alignItems={"center"} gap={"16px"}>
              <img
                src={
                  IMAGE_URL_ROOT + item.banner?.replace("static/images/", "")
                }
                alt="banner"
                style={{ width: "100px", height: "60px", minWidth: "100px" }}
              />
              <Box>
                <Typography
                  fontWeight={600}
                  sx={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                  }}
                >
                  {language === "vi" ? item.title : item.en_title}
                </Typography>
                <Typography fontSize={11} color="#8c8b8b">
                  {language === "vi"
                    ? formatDateStringToLocalDate(
                        item.created_at,
                        "DD/MM/YYYY",
                        "vi"
                      )
                    : formatDateStringToLocalDate(
                        item.created_at,
                        "MMM DD, YYYY"
                      )}
                </Typography>
              </Box>
            </Box>
          </Link>
          {/* <BlogListItem
            img={item.banner}
            content={language === "vi" ? item.content : item.en_content}
            title={language === "vi" ? item.title : item.en_title}
            slug={item.id}
            date={item.created_at}
          /> */}
        </Grid>
      );
    });
    return blogList;
  };
  console.log(process.env.REACT_APP_URL);
  console.log(IMAGE_URL_ROOT);
  return (
    <Box component={"article"}>
      <Helmet>
        <meta property="og:title" content={blogContent.title} />
        <meta property="og:image" content={blogContent.image} />
        <title>{blogContent.title}</title>
      </Helmet>
      <Box
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${banner})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 600,
          marginBottom: "50px",
          "@media (max-width: 500px)": {
            height: 300,
            marginBottom: "15px",
          },
        }}
      >
        <Grid container height="inherit">
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"center"}
            mb="60px"
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"10px 20px"}
              sx={{
                background: "#d61f23",
                color: "white",
                fontSize: "30px",
                textTransform: "uppercase",
                marginBottom: "15px",
              }}
            >
              <Typography
                onClick={() => navigate("/")}
                sx={{ cursor: "pointer" }}
              >
                Home
              </Typography>
              <ChevronRight />
              <Typography>Blog</Typography>
            </Box>
            <Typography className="text-banner">Blog</Typography>
          </Grid>
        </Grid>
        <div ref={targetRef} />
      </Box>

      {/* Content */}
      <Box
        padding={" 80px"}
        display={"flex"}
        justifyContent="center"
        sx={{
          "@media (max-width: 500px)": {
            padding: "10px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "1200px",
            justifyContent: "space-between",
            flexDirection: { xs: "column", lg: "row" },
            width: "100%!important",
            marginLeft: "0px!important",
            marginTop: "0px!important",
            position: "relative",
          }}
        >
          {/* Main content */}
          <Box
            style={{
              paddingTop: 0,
              paddingLeft: 0,
            }}
            width={{ xs: "100%", lg: "60%" }}
          >
            <Typography
              sx={{
                fontSize: "34px",
                margin: "20px 0 0 0",
                textTransform: "uppercase",
                textAlign: "center",
              }}
              component={"h1"}
            >
              {language === "vi" ? blogContent.title : blogContent.en_title}
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"6px"}
              mb={"20px"}
            >
              <LiaCalendarAltSolid color={"#8c8b8b"} size={19} />
              <Typography
                sx={{
                  fontSize: "16px",
                  margin: "4px 0",
                  textAlign: "center",
                  color: "#8c8b8b",
                }}
              >
                {language === "vi"
                  ? formatDateStringToLocalDate(
                      blogContent.created_at,
                      "DD [tháng] MM [năm] YYYY",
                      "vi"
                    )
                  : formatDateStringToLocalDate(
                      blogContent.created_at,
                      "MMM DD, YYYY"
                    )}
              </Typography>
              <Box width={"1px"} height={"22px"} bgcolor={"#8c8b8b"} />
              <Typography
                sx={{
                  fontSize: "16px",
                  margin: "4px 0",
                  textAlign: "center",
                  color: "#8c8b8b",
                }}
              >
                5 {t("read_time")}
              </Typography>
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <ImageHandle
                src={
                  IMAGE_URL_ROOT +
                  blogContent.banner?.replace("static/images/", "")
                }
                defaultSrc={blogBanner}
              />
            </Box>
            <Box
              width="100%"
              borderBottom={1}
              borderColor="divider"
              sx={{
                ".ck.ck-editor__main > .ck-editor__editable": {
                  bgcolor: "transparent",
                  border: "none",
                },
                "@media (max-width: 768px)": {
                  px: 1,
                },
              }}
            >
              <TextEditor
                initialData={
                  language === "vi"
                    ? blogContent.content
                    : blogContent.en_content
                }
                readonly={true}
                noEdit={true}
              />
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"center"}
              mt={"20px"}
              gap={"10px"}
            >
              <Typography fontWeight={600}>Share this blog:</Typography>
              <FacebookShareButton
                url={window.location.host + `/blogs/${id}`}
                style={{ height: "32px" }}
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <LinkedinShareButton
                url={window.location.host + `/blogs/${id}`}
                style={{ height: "32px" }}
              >
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
              <Button
                onClick={() => {
                  navigator.clipboard
                    .writeText(window.location.host + `/blogs/${id}`)
                    .then(() => {
                      alert(t("alert_copied"));
                    });
                }}
                sx={{
                  minWidth: "32px",
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  bgcolor: "green",
                  "&:hover": {
                    bgcolor: "green",
                  },
                }}
              >
                <IoIosLink color="white" size={20} />
              </Button>
            </Box>
          </Box>

          {/* <Grid
            item
            md={4}
            sx={{
              "@media (max-width: 600px": {
                display: "none",
              },
            }}
          >
            <TableOfContent headingList={contentTableHeads} />
          </Grid> */}
          <Box
            width={{ xs: "100%", lg: "36%" }}
            position={"sticky"}
            top={"100px"}
            height={"fit-content"}
          >
            <Typography
              fontWeight={600}
              fontSize={{ xs: 20, md: 24 }}
              textTransform={"uppercase"}
              my={"20px"}
            >
              {t("related_blogs")}
            </Typography>
            <Box
              display={"flex"}
              justifyContent="center"
              sx={{
                "@media (max-width: 500px)": {
                  padding: "10px",
                },
              }}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={6}
                sx={{
                  maxWidth: "100%",
                  width: "100%!important",
                  marginLeft: "0px!important",
                  marginRight: "0px!important",
                  "@media (max-width: 500px)": {
                    padding: "10px",
                  },
                }}
              >
                {blogCardList(
                  blogList?.filter((item) => item.id !== blogContent.id)
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
